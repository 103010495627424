<template>
<!-- eslint-disable -->
  <NavBarResume class="z-30" @scrollPosition="scrollPositionApp" @openNavBar="openNavBar"/>

<div v-if="showDesktopContent">
<body>

<div class="resume">
   <div class="resume_left">
     <div class="resume_profile">
       <img src="@/assets/slikice/resume/profile.jpg" alt="profile_pic">
     </div>
     <div class="resume_content">
       <div class="resume_item resume_info">
         <div class="title">
           <p class="bold" style="color:yellow;">Matija Corak</p>
           <p class="regular">Full-stack developer</p>
         </div>
         <ul>
            <li>
             <div class="icon">
               <i class="bi bi-globe"></i>
             </div>
             <div class="data">
               Haraciceva 2 Mali Losinj <br /> Croatia
             </div>
           </li>
           <li>
             <div class="icon">
               <i class="bi bi-telephone"></i>
             </div>
             <div class="data">
               +385 91 723 6143
             </div>
           </li>
           <li>
             <div class="icon">
               <i class="bi bi-envelope"></i>
             </div>
             <div class="data">
               mcorak22@gmail.com
             </div>
           </li>
         </ul>
       </div>
       <div class="resume_item resume_skills">
         <div class="title">
           <p class="bold">skill's</p>
         </div>
          <ul v-if="this.resumeInfo.length">
             <div v-for="(value, key, index) in this.resumeInfo[0].Skills" :key="index">
                <div class="title">
                  <div style="color:white;">- {{key}}</div>
                </div>
                
                <li v-for="skill in value" :key="skill">
                  <div class="skill_name">
                    {{skill.name}}
                  </div>
                  <div class="skill_progress">
                    <span :style="{ width: skill.value }"></span>
                  </div>
                  <div class="skill_per">{{skill.value}}</div>
                </li>
                <br>
              </div>
         </ul>
       </div>
     </div>
  </div>
  <div class="resume_right">
    <div class="resume_item resume_about">
        <div class="title">
           <p class="bold" id="about">About me</p>
         </div>
         <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
        <p v-if="this.resumeInfo.length" >{{this.resumeInfo[0].About}}</p>
    </div>
    <div class="resume_item resume_work">
        <div class="title">
           <p class="bold" id="experience">Work Experience</p>
         </div>
         <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
        <ul v-if="this.resumeInfo.length">
            <li v-for="experience in this.resumeInfo[0].Experience" :key="experience">
                <div class="date">{{experience.year}}</div> 
                <div class="info">
                     <p class="semi-bold">{{experience.name }} - {{experience.jobTitle}}</p> 
                  <p>{{experience.jobDescription}}</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="resume_item resume_education">
      <div class="title">
           <p class="bold" id="education">Education</p>
         </div>
         <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
      <ul v-if="this.resumeInfo.length">
            <li v-for="education in this.resumeInfo[0].Education" :key="education">
                <div class="date">{{education.year}}</div> 
                <div class="info">
                     <p class="semi-bold">{{education.name}} ({{education.country}})</p> 
                  <p>{{education.title}}</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="resume_item resume_hobby">
      <div class="title">
           <p class="bold" id="hobby">Hobby</p>
         </div>
       <ul>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/1.png" class="hobbyImg"></li>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/2.png" class="hobbyImg"></li>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/3.png" class="hobbyImg"></li>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/4.png" class="hobbyImg"></li>
      </ul>
    </div>
  </div>
</div>


</body>
</div>
<div v-if="showMobileContent">

  <body>
    <br><br>
    <div class="container" style="max-width: 600px; margin: auto; padding: 20px;">
      <div class="profile" style="text-align: center;">
        <img src="@/assets/slikice/resume/profile.jpg" alt="Profile Picture" 
              style=" display: block;
                      width: 250px;    
                      height: 250px;    
                      object-fit: cover;    
                      border-radius: 50%;    
                      border: 5px solid #0bb5f4;    
                      margin: 0 auto 20px;">
        <h1 style="margin: 0; color:yellow;">Matija Corak</h1>
      </div>
      
    </div>
    <div class="resume">
      <div class="resume_left">
     
     <div class="resume_content">
       <div class="resume_item resume_info">
         
         <ul>
           <li>
             <div class="icon">
               <i class="bi bi-globe"></i>
             </div>
             <div class="data">
               Haraciceva 2 Mali Losinj <br /> Croatia
             </div>
           </li>
           <li>
             <div class="icon">
               <i class="bi bi-telephone"></i>
             </div>
             <div class="data">
               +385 91 723 6143
             </div>
           </li>
           <li>
             <div class="icon">
               <i class="bi bi-envelope"></i>
             </div>
             <div class="data">
               mcorak22@gmail.com
             </div>
           </li>
         </ul>
       </div>
       <div class="resume_item resume_skills">
          
         <div class="title">
           <p class="bold">skill's</p>
         </div>
         <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring" style="color:white;"></div>
        </div>
          <ul v-if="this.resumeInfo.length">
             <div v-for="(value, key, index) in this.resumeInfo[0].Skills" :key="index">
                <div class="title">
                  <div style="color:white;">- {{key}}</div>
                </div>
                
                <li v-for="skill in value" :key="skill">
                  <div class="skill_name">
                    {{skill.name}}
                  </div>
                  <div class="skill_progress">
                    <span :style="{ width: skill.value }"></span>
                  </div>
                  <div class="skill_per">{{skill.value}}</div>
                </li>
                <br>
              </div>
         </ul>
       </div>
     </div>
  </div>
    </div>
    <div class="resume">
   
  <div class="resume_right">
    <div class="resume_item resume_about" style="text-align:center;">
        <div class="title">
           <p class="bold" id="about">About me</p>
        </div>
        <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
        <p v-if="this.resumeInfo.length" style="color:black;">{{this.resumeInfo[0].About}}</p>
    </div>
    <div class="resume_item resume_work">
        <div class="title">
           <p class="bold" id="experience">Work Experience</p>
         </div>
         <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
        <ul v-if="this.resumeInfo.length">
            <li v-for="experience in this.resumeInfo[0].Experience" :key="experience">
                <div class="date">{{experience.year}}</div> 
                <div class="info">
                     <p class="semi-bold">{{experience.name }} - {{experience.jobTitle}}</p> 
                  <p>{{experience.jobDescription}}</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="resume_item resume_education">
      <div class="title">
           <p class="bold" id="education">Education</p>
         </div>
         <div class="is-loading-bar has-text-centered"  style="text-align:center;" v-bind:class="{'is-loading': this.$store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
      <ul v-if="this.resumeInfo.length">
            <li v-for="education in this.resumeInfo[0].Education" :key="education">
                <div class="date">{{education.year}}</div> 
                <div class="info">
                     <p class="semi-bold">{{education.name}} ({{education.country}})</p> 
                  <p>{{education.title}}</p>
                </div>
            </li>
        </ul>
    </div>
    <div class="resume_item resume_hobby">
      <div class="title" style="text-align:center;">
           <p class="bold" id="hobby">Hobby</p>
         </div>
       <ul>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/1.png" class="hobbyImg"></li>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/2.png" class="hobbyImg"></li>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/3.png" class="hobbyImg"></li>
         <li class="hobbyLi"><img src="@/assets/slikice/resume/4.png" class="hobbyImg"></li>
      </ul>
    </div>
  </div>
</div>
    
  </body>


</div>

<FooterResume id="contact"/>
  
  
</template>

<script>
/* eslint-disable*/
import NavBarResume from '@/components/Resume/NavbarComponentResume.vue';
import FooterResume from '@/components/Resume/FooterComponentResume.vue';
import { resumeCollection } from '@/includes/firebase';

export default {
  /* eslint-disable*/
  name:'Resume',
  data(){
    return {
      resumeInfo: [],
    }
  },
  components: {
    NavBarResume,
    FooterResume
  },
  computed: {
    showDesktopContent() {
      return window.matchMedia('(min-width: 680px)').matches;
    },
    showMobileContent() {
      return window.matchMedia('(max-width: 679px)').matches;
    }
  },
  async created() {
    this.$store.state.isLoading = true;
    const snapshot = await resumeCollection.get();
    snapshot.forEach((document) => {
      const resume = {
        ...document.data(),
      };

      this.resumeInfo.push(resume);
      this.$store.state.isLoading = false;
    });
  },
  
}

</script>

<style scoped>
/* eslint-disable*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

body {
  background: #585c68;
  font-size: 14px;
  line-height: 22px;
  /* color: #555555; */
  color: black;
}

.bold {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.semi-bold {
  font-weight: 500;
  font-size: 16px;
}

.resume {
  /* width: 800px; */
  height: auto;
  display: flex;
  margin: 58px 0 auto;
}

.resume .resume_left {
  width: 320px;
  background: #0bb5f4;
}

.resume .resume_left .resume_profile {
  width: 100%;
  height: 280px;
}

.resume .resume_left .resume_profile img {
  width: 100%;
  height: 100%;
}

.resume .resume_left .resume_content {
  padding: 0 25px;
}

.resume .title {
  margin-bottom: 20px;
}

.resume .resume_left .bold {
  color: #fff;
}

.resume .resume_left .regular {
  color: #b1eaff;
}

.resume .resume_item {
  padding: 25px 0;
  border-bottom: 2px solid #b1eaff;
}

.resume .resume_left .resume_item:last-child,
.resume .resume_right .resume_item:last-child {
  border-bottom: 0px;
}

.resume .resume_left ul li {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.resume .resume_left ul li:last-child {
  margin-bottom: 0;
}

.resume .resume_left ul li .icon {
  width: 35px;
  height: 35px;
  background: #fff;
  color: #0bb5f4;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 16px;
  position: relative;
}

.resume .icon i,
.resume .resume_right .resume_hobby ul li i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}

.resume .resume_right .resume_hobby ul li svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.resume .resume_left ul li .data {
  color: #b1eaff;
}

.resume .resume_left .resume_skills ul li {
  display: flex;
  margin-bottom: 10px;
  color: #b1eaff;
  justify-content: space-between;
  align-items: center;
}

.resume .resume_left .resume_skills ul li .skill_name {
  width: 25%;
}

.resume .resume_left .resume_skills ul li .skill_progress {
  width: 50%;
  margin: 0 5px;
  height: 5px;
  background: #009fd9;
  position: relative;
}

.resume .resume_left .resume_skills ul li .skill_per {
  width: 15%;
}

.resume .resume_left .resume_skills ul li .skill_progress span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
}

.resume .resume_left .resume_social .semi-bold {
  color: #fff;
  margin-bottom: 3px;
}

.resume .resume_right {
  /* width: 520px; */
  width: 70%;
  background: #fff;
  /* padding: 25px; */
  padding: 2% 2% 5% 5%;
}

.resume .resume_right .bold {
  color: #0bb5f4;
}

.resume .resume_right .resume_work ul,
.resume .resume_right .resume_education ul {
  padding-left: 40px;
  overflow: hidden;
}

.resume .resume_right ul li {
  position: relative;
}

.resume .resume_right ul li .date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.resume .resume_right ul li .info {
  margin-bottom: 20px;
}

.resume .resume_right ul li:last-child .info {
  margin-bottom: 0;
}

.resume .resume_right .resume_work ul li:before,
.resume .resume_right .resume_education ul li:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -24px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background:white;
  border: 2px solid #0bb5f4;
}

.resume .resume_right .resume_work ul li:after,
.resume .resume_right .resume_education ul li:after {
  content: "";
  position: absolute;
  top: 14px;
  left: -21px;
  width: 2px;
  height: 115px;
  background: #0bb5f4;
}

.resume .resume_right .resume_hobby ul {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.resume .resume_right .resume_hobby ul li {
  width: 80px;
  height: 80px;
  border: 2px solid #0bb5f4;
  border-radius: 50%;
  position: relative;
  color: #0bb5f4;
  background: #fff;
}

.resume .resume_right .resume_hobby ul li i {
  font-size: 30px;
}

.resume .resume_right .resume_hobby ul li:before {
  content: "";
  position: absolute;
  top: 40px;
  right: -252px;
  width: 250px;
  height: 2px;
  background: #0bb5f4;
}

.resume .resume_right .resume_hobby ul li:last-child:before {
  display: none;
}

.hobbyLi{
  position: relative;
  text-align: center;
}
.hobbyImg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 660px) {
  
  .resume {
    /* width: 800px; */
    height: auto;
    display: flex;
    margin: 10px 0 auto;
  }
   .resume .resume_right {
    width: 100%;
    background: #fff;
    padding: 0% 10% 10% 10%;
  }
  .resume .resume_left {
    width: 100%;
    background: #0bb5f4;
  }

}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #20c5b5;
  border-color: #20c5b5 transparent #20c5b5 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.is-loading {
  height: 80px;
}
</style>
