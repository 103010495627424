export default {
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naslovna"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONama"])},
    "apartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartmani"])},
    "apartmentsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcije"])},
    "apartmentsAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupnost"])},
    "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priznanja"])},
    "findUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NadiNas"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popunjenost"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MojProfil"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OdjaviSe"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobro dosli!!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TKO STOJI IZA ANTARES APARTMANI?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartmani Antares potpuno renovirani od 2015 imaju dugugodisnju turisticku uslugu, koja se proteze zanjih 60 godina unutar dvije generacije. Godine iskustva i svakodnevna zelja za napredkom cine nasu neospornu kvalitetu. Modernizirani apartmani ispunjeni svakojakim uredajima i neogranicenom ponudom omoguciti ce Vam godisnji bez ikakve brige ili ogranicenja. Antares Apartmani stoje izmedu dvije prekrasne uvale, uvale Cikat i Suncane uvale te grada Malog Losinja. Na samo 50 metara s lakocom mozete do ducana, bankomata ili drugih preko potrebnih usluga. Ukoliko zelite do srca grada, manje od 300 metara Vas dijeli od glavne gradske setnice. Na samo 10 minuta su nase prve plaze. Domacini su dragi bracni par, Suzi i Tomislav Corak koji uz dvoje vec odrasle djece uspjesno odrzavaju svoj brak preko 30 godina. Poznavanje jezika, velikodusnost i zelja da Vas boravak u Antares rezidenciji bude nezaboravan cine idealan par domacina. Dođite kao gosti, otiđite kao prijatelji. Očekujemo vas s osmijehom."])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da li ste se ikad zapitali tko se brine o Vama? Da li ste znali da ekipa se sastoji od tri clana, jedan bracni par i jedna Kapetanica. Vid, upoznaj Vase nove prijatelje!"])},
    "mama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ova karizmatična dama po imenu SUZI jedan je od vaših domaćina. Kraljica našeg srca, uvijek je tu za tebe. Njezina je jedina misao učiniti vas sretnima kako biste se s odmora vratili savršeno odmorni s neograničenim pozitivnim iskustvom. Njezine marketinške vještine i brzina reprodukcije nikada vas neće ostaviti na čekanju. Ona je mozak ove operacije."])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sefica"])},
    "tata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovaj neustrašivi ribar po imenu TOMISLAV jedan je od vaših domaćina. Kapetan mora, iskusan je ribar s velikim znanjem o otoku Lošinju. Njegova ljubav prema ribolovu učinila ga je izvrsnim jedriličarem koji vam može preporučiti sve ljepote koje morate vidjeti. Osim tiga njegov talent za kuhanje je neizbježan."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapetan"])},
    "dama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovaj elegantni brod imena DAMA jedan je od vaših domaćina. Dama otoka Lošinja je ljepotica koja oduzima dah koju ne smijete zaobići. Samo ona zna sve tajne dubina. Bez obzira na vrijeme, ona vas može i hoće dovesti kući. Ona može učiniti vaše vrijeme na otoku nezaboravnim."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prava dama"])}
  },
  "apartments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartmani"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imamo dva apartmana. Potpuno su opremljeni. Nadamo se da ce Vam se svidati."])},
    "reviewsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentari"])},
    "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidi dostupnost"])}
  },
  "calendar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupnost"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako je cijena definirana s * molimo kontaktirajte nas za navedene dane."])},
    "offersBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uz cijenu od"])},
    "offersEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["po danu."])},
    "specialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specijalne ponude"])},
    "noOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenutacno nema specijalnih ponuda."])}
  },
  "awards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagrade"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvala Vam od srca! Zbog Vas dobivamo ove nagrade."])}
  },
  "findUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidi vise"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako se izgubite mi smo tu da Vam pomognemo. Za pocetak pokusajte naci tu svoj put."])},
    "didYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeste li stigli?"])},
    "carDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanca autom:"])},
    "timeDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrebno vrijeme:"])},
    "aroundUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oko nas...(vidi vise)"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vise"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lokacija"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta:"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanca:"])},
    "antaresApartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antares Apartmani"])},
    "type0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuca"])},
    "veliLosinj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veli Losinj"])},
    "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grad / razgledavanje"])},
    "providenca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providenca"])},
    "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panorama"])},
    "apoxyomenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzej Apoksiomena"])},
    "type3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzej"])},
    "blueWorld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plavi svijet"])},
    "type4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
    "arheoOsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arheoloski Muzej"])},
    "type5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muzej"])},
    "superMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarket"])},
    "type6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ducan"])},
    "atm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankomat"])},
    "type7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankomat"])},
    "cikatBay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvala Cikat"])},
    "type8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kupanje / setnja"])},
    "info8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Cikat Bay</b><br/>Nature"])},
    "suncana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvala Suncana"])},
    "type9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kupanje / setnja"])}
  },
  "contactUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiraj nas!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt broj"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja porijekla"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upit"])}
  },
  "footer": {
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiraj nas"])},
    "visitUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posjesti nas"])},
    "knowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelis znati vise?"])}
  },
  "reviews": {
    "description0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedan od najboljih odmora ikad. Svakako bih preporučio ovu destinaciju zbog nevjerojatnih vlasnika kao i zbog lokacije ..."])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrlo opušten boravak i veliko hvala domaćinima vratit ćemo se sljedeće godine. Srdačni domaćini Suzi i Tomislav koji su uvijek dostupni savjetima ..."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odličan apartman za obitelj, odlična lokacija, izvrstan domaćin! Odličan stan! Ne mogu se sjetiti nijedne stvari koja nije bila sjajna! ..."])},
    "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastičan apartman za odmor s fantastičnim domaćinima. Prekrasan, s ljubavlju namješten apartman za odmor. Sve je tu ..."])},
    "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobili smo više nego što smo očekivali. Lijepi i uslužni domaćini, ponudili su svoje privatno vrijeme i podršku u svakom pitanju. Pripremili su lijepa iznenađenja, ..."])},
    "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrlo ljubazan domaćin, osjećala sam se vrlo ugodno. Hvala vam na gostoprimstvu! Jako mi se svidjela otvorenost i susretljivost Suzi i Tome ..."])}
  },
  "amenities": {
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parkiraliste"])},
    "airConditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klima uredaj"])},
    "heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grianje"])},
    "kingSizeBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veliki kreveti"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI"])},
    "hifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIFI"])},
    "dishWasher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perilica za sude"])},
    "owenStove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pecnica i stednjak"])},
    "washingMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perilica za rublje"])},
    "fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hladnja"])},
    "frizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zamrzivac"])},
    "barbique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rostilj"])},
    "microwave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mikrovalna"])},
    "blender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blender"])},
    "iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pegla"])},
    "sateliteTV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["satelitska TV"])},
    "hbo(SUPERIOR)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hbo (SUPERIOR)"])},
    "netflix(SUPERIOR)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["netflix (SUPERIOR)"])},
    "babyChair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["djecja stollica"])},
    "babyCradle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["djecji krevetic"])},
    "toys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["igracke"])},
    "boardGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drustvene igre"])}
  }
}