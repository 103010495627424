<template>
<div>
  <div v-if="$route.path !== '/MyResume'">
    <link rel="preload" :href="require('@/assets/slikice/backgroundimg/test/1.webp')"
          as="image">
    <link rel="preload" :href="require('@/assets/slikice/backgroundimg/test/2.webp')"
    as="image">
    <link rel="preload" :href="require('@/assets/slikice/backgroundimg/test/3.webp')"
    as="image">
    <link rel="preload" :href="require('@/assets/slikice/backgroundimg/test/4.webp')"
    as="image">

    <div ref="div2">
      <div style="overflow:hidden; z-index:-1;"
          :style="{ 'height': backGHeight + 'vh' }">
          <HeaderImagesCarousel/>
      <!-- <BackgroundImageRender/> -->
      </div>
      <NavBar class="z-30" @scrollPosition="scrollPositionApp" @openNavBar="openNavBar"/>
      <!-- eslint-disable -->
      <HeaderComponent :open="this.open" :scrollPosition="this.scrollPosition" />
      <section id="user-info" style="  border-bottom: 3px solid #20C5B5;"></section>
      <div class="routerView z-20 bg-white" id="routerContainer" style="min-height:100vh;" ref="div1">
        <router-view/>
      </div>
      <FooterComponent @scroll-to-top="scrollToTop"/>
    </div>
  </div>
  <div v-else>
    <MyResume/>
  </div>
</div>
</template>

<script>
import NavBar from '@/components/NavbarComponent.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
// import BackgroundImageRender from '@/components/BackgroundImageRender.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import HeaderImagesCarousel from '@/components/HeaderImagesCarousel.vue';

import MyResume from '@/views/MyResume.vue';

export default {
  /* eslint-disable*/
  name:'Home',
  components: {
    NavBar,
    HeaderComponent,
    // BackgroundImageRender,
    FooterComponent,
    MyResume,
    HeaderImagesCarousel
  },
  data() {
    return {
      backGHeight: 100,
      backGHeightMainPicture: 50,
      scrollPosition: null,
      open: false,
    };
  },
  created() {
    this.$store.dispatch('init_login');
  },
  methods:{
    scrollPositionApp(refName) {
      this.backgroundImageSize(refName[1]);
      var element = this.$refs[refName[0]];
      //OSTAVIO SAM OVO U SLUCAJU DA ZELIM PONOVNO DINAMICKU POZICIJU NAPRAVITI OVISNO O VELICINI HEADERA
      let add = 0
      if(refName[1].indexOf('/MyProfile') !== (-1)){
        add = 60
      }
      if(refName[1].indexOf('/logout') !== (-1)){
        add = 60
      }
      if (this.$router.options.history.state.back === '/') {
          var top = element.offsetTop-0 - add;
          window.scrollTo({top:top, left:0, behavior:'smooth'});
      } else {
          var top = element.offsetTop - add;
          window.scrollTo({top:top, left:0, behavior:'smooth'});
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    backgroundImageSize(pathPosition) {
      //OSTAVIO SAM OVO U SLUCAJU DA ZELIM PONOVNO DINAMICKU POZICIJU NAPRAVITI OVISNO O VELICINI HEADERA
        if(pathPosition !== '/') {
            // this.backGHeight = 80;
            this.backGHeight = 100;
            // this.backGHeightMainPicture = 40;
            this.backGHeightMainPicture = 50;
        } else {
            this.backGHeight = 100;
            this.backGHeightMainPicture = 50;
        }
    },
    openNavBar(open) {
      this.open = open;
      this.scrollPosition = window.innerWidth;
    },
  },
  mounted() {
    document.title = 'Antares Apartments Mali Losinj - Official Apartment Page';
  },
};
</script>
<style scoped>


#routerContainer {
  /* background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(assets/slikice/nagrade/webp/awards32low.webp);    */
  background-image: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.2)), url(assets/slikice/nagrade/webp/awards32low.webp);   
  transition: background-image 0.5s;
  -webkit-transition: background-image 0.5s linear;
  background-position: center;  
  background-size: cover; 
  background-attachment: fixed;  

}
@media screen and (max-width: 500px) {
  #routerContainer {
    background-color: #A9A9A9;
    background-image: none;  
  }
}
</style>
