/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import AboutUs from '@/views/AboutUs.vue';
// import OurAwards from '@/views/OurAwards.vue';
// import HomePage from '@/views/HomePage.vue';
// import MamaStranica from '@/views/MamaStranica.vue';
import store from '@/store';
// import OurApartments from '@/views/OurApartments.vue';

const AboutUs = () => import(/* webpackChunkName: "AboutUs" */ '@/views/AboutUs.vue');
const OurAwards = () => import(/* webpackChunkName: "OurAwards" */ '@/views/OurAwards.vue');
const HomePage = () => import(/* webpackChunkName: "HomePage" */ '@/views/HomePage.vue');
const MyProfile = () => import(/* webpackChunkName: "MyProfile" */ '@/views/MyProfile.vue');
const OurApartments = () => import(/* webpackChunkName: "OurApartments" */ '@/views/OurApartments.vue');
const FindUs = () => import(/* webpackChunkName: "FindUs" */ '@/views/FindUs.vue');
const MyResume = () => import(/* webpackChunkName: "MyResume" */ '@/views/MyResume.vue');
const OurCalendar = () => import(/* webpackChunkName: "OurCalendar" */ '@/views/OurCalendar.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/AboutUs',
    name: 'aboutUs',
    component: AboutUs,
  },
  {
    path: '/OurAwards',
    name: 'ourAwards',
    component: OurAwards,
  },
  {
    path: '/OurApartments',
    name: 'ourApartments',
    component: OurApartments,
  },
  {
    path: '/FindUs',
    name: 'findUs',
    component: FindUs,
  },
  {
    path: '/MyResume',
    name: 'myResume',
    component: MyResume,
  },
  {
    path: '/OurCalendar',
    name: 'ourCalendar',
    component: OurCalendar,
    // beforeEnter: async(to, from, next) => {
    //   // Check if the calendar is available
    //   const calendarAvailable = await individualPinsCollection.doc("RnSuecQCuz823Hl6LVlu").get();
    //   const isCalendarAvailable = calendarAvailable.get('calendarAvailable');

    //   const currentUser = auth.currentUser;
    //   const userId = currentUser ? currentUser.uid : null;

    //   // If the user is authenticated and the calendar is available, allow access to the MyProfile component
    //   if (isCalendarAvailable || userId == 'xLByoFtaWvchNEIGWYrJpFHuOJ82') {
    //     next();
    //   } else {
    //     // If the user is not authenticated or the calendar is not available, redirect to the login page
    //     next('/');
    //   }
    // }, 
  },
  {
    path: '/MyProfile',
    name: 'myProfile',
    meta: {
      requiresAuth: true,
    },
    component: MyProfile,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'home' },
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeComponent,
  // },
  // {
  //   path: '/auth',
  //   name: 'auth',
  //   component: AuthComponent,
  // },
  // {
  //   path: '/contactus',
  //   name: 'contactus',
  //   component: ContactUsComponent,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (store.state.userLoggedIn) {
    next();
  } else {
    next({ name: 'home' });
  }
});

export default router;
