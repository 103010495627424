export default {
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AboutUs"])},
    "apartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartments"])},
    "apartmentsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "apartmentsAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards"])},
    "findUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FindUs"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ContactUs"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyProfile"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHO IS BEHIND ANTARES APARTMENTS?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartments Antares, completely renovated since 2015, have a long-standing tourist service, which extends over 60 years within two generations. Years of experience and a daily desire for progress make our quality undeniable. Modernized apartments filled with all kinds of devices and an unlimited offer will enable you to spend the holiday without any worries or restrictions. Antares Apartments stand between two beautiful coves, Cikat and Suncane coves and the town of Mali Losinj. You can easily reach shops, ATMs or other essential services just 50 meters away. If you want to get to the heart of the city, less than 300 meters separates you from the main city street. Our first beaches are just 10 minutes away. The hosts are a lovely married couple, Suzi and Tomislav Corak, who have successfully maintained their marriage for over 30 years with two grown-up children. Knowledge of the language, generosity and desire to make your stay at the Antares residence unforgettable make them an ideal pair of hosts. Come as guests, leave as friends. We welcome you with a smile."])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you ever wonder who is behind Apartments Antares? Did you know that the team is made of 3 individuals, one couple and one Captain. Check it out, meet your hosts!"])},
    "mama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This charismatic lady by the name SUZI is one of your hosts. The queen of our heart, is always here for you. Her only thought is to keep you happy so you can return from holiday perfectly restfull with unlimited positive experience. Her Marketing skills and speed of the replay will never let you on the hold. She is the brain of this operation."])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Boss"])},
    "tata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fearless fisherman by the name TOMISLAV is one of your hosts. The Captian of the sea, is expereinced fisherman with huge knowledge about island of the Losinj. His love torwards fishing made him amazing sailer which can recommend you all the beauties which you must see. Except that his cooking talent is inevitable."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Capitan"])},
    "dama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This elegant ship named DAMA is one of your hosts. The lady of the island of Lošinj is a breathtaking beauty that you should not miss. Only she knows all the secrets of the depths. Regardless of the weather, she can and will bring you home. She can make your time on the island unforgettable."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Lady"])}
  },
  "apartments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartments"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have two apartments. They are super cozy. We hope you will like it."])},
    "reviewsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Availability"])}
  },
  "calendar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If price has * please verify with us which offer we can give you for this days"])},
    "offersBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for price of the"])},
    "offersEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per night."])},
    "specialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Offers"])},
    "noOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no special offers right now."])}
  },
  "awards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you! Because of you we have these awards."])}
  },
  "findUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Us"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you get lost we are here to help. For start find your way here."])},
    "didYouArrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you already arrive?"])},
    "carDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Distance:"])},
    "timeDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Duration:"])},
    "aroundUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Around us...(click for more)"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type:"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance:"])},
    "antaresApartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antares Apartments"])},
    "type0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "info0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "veliLosinj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veli Losinj"])},
    "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["town / sighseeing"])},
    "providenca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providenca"])},
    "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewpoint"])},
    "apoxyomenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Museum of Apoxyomenos"])},
    "type3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["museum"])},
    "blueWorld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue World"])},
    "type4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["museum"])},
    "arheoOsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archeological Museum"])},
    "type5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["museum"])},
    "superMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarket"])},
    "type6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shop"])},
    "atm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
    "type7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM"])},
    "cikatBay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cikat Bay"])},
    "type8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swim / walk"])},
    "suncana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suncana Bay"])},
    "type9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swim / walk"])}
  },
  "contactUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PhoneNumber"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])}
  },
  "footer": {
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "visitUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more"])},
    "knowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to know more about us?"])}
  },
  "reviews": {
    "description0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the best vacations ever. I would definitely recommend this property due to both the incredible owners as well as the location ..."])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very relaxed stay and many thanks to the hosts we will be back next year. The warm hosts Suzi and Tomislav who are always available to help with tips ..."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great apartment for a family, great location, great host! Great apartment! I can't think of a single thing that wasn't great! ..."])},
    "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic holiday apartment with fantastic hosts. A beautiful, lovingly furnished holiday apartment. Everything is there ..."])},
    "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We got more than we expected. Nice and helpful hosts, they offered their private time and support in every matter. They prepared nice surprises, ..."])},
    "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very nice host, I felt very comfortable. Thank you for your hospitality! I really liked the openness and friendliness of Suzi and Tomo ..."])}
  },
  "amenities": {
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parking"])},
    "airConditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["air conditioner"])},
    "heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heating"])},
    "kingSizeBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["king size bed"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI"])},
    "hifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIFI"])},
    "dishWasher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dish washer"])},
    "owenStove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["owen & stove"])},
    "washingMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["washing machine"])},
    "fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fridge"])},
    "frizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frizer"])},
    "barbique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["barbique"])},
    "microwave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["microwave"])},
    "blender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blender"])},
    "iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iron"])},
    "sateliteTV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["satelite TV"])},
    "hbo(SUPERIOR)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hbo (SUPERIOR)"])},
    "netflix(SUPERIOR)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["netflix (SUPERIOR)"])},
    "babyChair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baby chair"])},
    "babyCradle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baby cradle"])},
    "toys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toys"])},
    "boardGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["board games"])}
  }
}