<template>
<!-- eslint-disable -->
    <section class="w-screen text-center">
      <div id="background" class="items-center">
        <div class="grid grid-cols-1 px-0 mb-5">
          <h1 class="text-white text-2xl">
            Contact me
          </h1>

          <div style="position:relative; height:90px; ">
            <ul>
              <li><a href="tel:00385917236143"  aria-label="Contact me"><i class="bi bi-telephone-fill" aria-hidden="true" title="Call me"></i></a></li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=385917236143&text=Hi I would like to contact you"
                    target="https://api.whatsapp.com/send?phone=385917236143&text=Hi I would like to contact you"
                    aria-label="WhatsApp me" title="WhatsApp me">
                  <i class="bi bi-whatsapp" aria-hidden="true"></i>
                </a>
              </li>
              <li><a href="mailto:mcorak22@gmail.com"  aria-label="Email me"><i class="bi bi-envelope" aria-hidden="true" title="Email me"></i></a></li>
            </ul>
            
          </div>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;tel: (+385) 91 723 6143 | email: mcorak22@gmail.com</p>
          <br>
          <div class="text-xl">
            <h1 class="text-white text-2xl">
            Find me here
          </h1>
          </div>
          <div style="position:relative; height:90px;">
            <ul>
              <!-- face -->
              <li>
                <a href="https://github.com/MatijaC22" target="https://github.com/MatijaC22" aria-label="Check out my Github profile" title="Github">
                  <i class="bi bi-github" aria-hidden="true"></i>
                </a>
              </li>
              
              <!-- instagram -->
              <li>
                <a href="https://www.linkedin.com/in/matija-%C4%8Dorak-8a0aa5124/" aria-label="Check out my linkedIn" title="LinkedIn">
                  <i class="bi bi-linkedin" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
          <br>
          <!-- <div>           
            <h1 class="text-white text-l">
              Download CV
          </h1>
          </div>  
          <div style="position:relative; height:90px;">
            <ul>
              <li>
                <a href="./ANTARES-info.pdf" download aria-label="Download my CV" title="Download my CV">
                  <i class="bi bi-download" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div> -->
          
      </div>
      <div class="text-white bg-black pt-1">
          <p>
            Made with
            <i class="bi bi-heart-fill text-red-600"></i>
            by <router-link to="/MyResume">Matija Corak</router-link>
          </p>
      </div>
    </div>
    <AuthComp/>
  </section>
</template>
<script>
import AuthComp from '@/components/Forms/AuthComp.vue';
import { mapMutations } from 'vuex';

export default {
  components: {
    AuthComp,
  },
  methods: {
    ...mapMutations(['toggleAuthModal']),
  },
};
</script>
<style scoped>

#background{
    font-family: 'Montserrat', sans-serif;
    padding-top:20px;
    background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(@/assets/slikice/footer.webp);
    background-position:center;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;
    border-top:3px solid #20C5B5;

}

ul {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  margin:0;
  padding:0;
  display:flex;
}

ul li {
  list-style: none;
}

ul li a {
  position: relative;
  width:60px;
  height:60px;
  display:block;
  text-align:center;
  margin:0 10px;
  border-radius: 50%;
  padding: 6px;
  box-sizing: border-box;
  text-decoration:none;
  box-shadow: 0 10px 15px rgba(0,0,0,0.3);
  background: linear-gradient(0deg, #ddd, #fff);
  transition: .5s;
}

@media (max-width:500px) {
    ul li a{
      margin:0 5px;
    }
}

ul li a:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  text-decoration:none;
}

ul li a .bi {
  width: 100%;
  height:100%;
  display:block;
  background: linear-gradient(0deg, #fff, #ddd);
  border-radius: 50%;
  line-height: calc(60px - 12px);
  font-size:24px;
  color: #262626;
  transition: .5s;
}

ul li a:hover .bi-linkedin {
  color: #007bb6;
}

ul li a:hover .bi-github {
  color: gray;
}
ul li a:hover .bi-telephone-fill{
  color:#007bb6;
}
ul li a:hover .bi-whatsapp {
  color: lawngreen;
}
ul li a:hover .bi-envelope {
  color: #00aced;
}
ul li a:hover .bi-download {
  color:darkblue;
}
</style>
