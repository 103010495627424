<template>
<!-- eslint-disable -->
  <!-- Contact Modal -->
  <div class="fixed z-50 inset-0 overflow-y-auto" id="modal"
    :class="{ hidden: !contactModalShow }" style="font-family: 'Montserrat', serif;">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center
      sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-800 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
        shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-4">
            <p class="text-2xl font-bold">{{ $t('contactUs.title') }}</p>
            <!-- Modal Close Button -->
            <div class="modal-close cursor-pointer z-90 w-7 h-7" @click.prevent="toggleContactModal">
              <i class="bi bi-x" style="font-size:25px;"></i>
            </div>
          </div>
          <ContactUsForm/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ContactUsForm from '@/components/Forms/ContactUsForm.vue';

export default {
  name: 'ContactForm',
  components: {
    ContactUsForm,
  },
  data() {
    return {
      // tab: 'login',
    };
  },
  computed: {
    ...mapState(['contactModalShow']),
  },
  methods: {
    ...mapMutations(['toggleContactModal']),
  },
  mounted() {
    document.title = 'Antares Apartments Mali Losinj - ContactUs';
  },
};
</script>
