<template>
<!-- eslint-disable -->
<div></div>
<transition-group name="fade">
  <div v-if="this.open && this.scrollPosition<450"
                          class="grid grid-cols-2 z-10 absolute text-center w-80
                           left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2"  
                          >
                          <div></div>
                          <div>
      <h1 class="text-4xl dinamicFontSize">Come as guests...</h1>
      <h1 class="text-4xl  dinamicFontSize">Leave as friends!</h1>
      <p class="text-2xl mt-10 dinamicFontSize">ANTARES APARTMENTS</p>
      <p class="text-2xl  dinamicFontSize">CORAK FAMILY</p>
      </div>
  </div>
    <div v-else class="z-10 content-center absolute text-center w-screen
                           left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2"
                          >
      <h1 class="text-4xl  dinamicFontSize">Come as guests, Leave as friends!</h1>
      <p class="text-2xl mt-10  dinamicFontSize">ANTARES APARTMENTS | CORAK FAMILY</p>
    </div>
</transition-group>
</template>

<script>

export default {
  data() {
    return {
      title: true,
    };
  },
  components: {
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    scrollPosition: {
      type: Number,
      // required: true,
    },
  },
};
</script>

<style scoped>
.dinamicFontSize {
    font-size: 40px;
    font-size: 4.5vw;
    color:white;
    font-family: 'Playfair Display', sans-serif;
}
.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  /* transform: translatey(-20px); */
}
.fade-enter-active,
.fade-leave-active{
 transition: all 0.5s ease-in-out;
 /* transition-delay: calc( 100ms * var(--index) ); */
}

@media screen and (max-width: 500px) {
  .dinamicFontSize {
    font-size: 60px;
    font-size: 6.5vw;
}
}
</style>
