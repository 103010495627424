<template>
<!-- eslint-disable -->
<div class="relative">
  <div class="fixed top-0 left-0 right-0
              bg-gray-900 bg-opacity-60
              py-3.5 px-6 shadow md:flex
              justify-between items-center" style="color:#20C5B5;"  ref="navBar">

    <div class="flex items-center cursor-pointer">
      <img class="object-scale-down"
           style= "width:30px; height:30px;"
           id="antares-star-logo" 
           alt="antares apartments mali losinj logo" 
           src="@/assets/slikice/antaresWebp.webp" />
      <h1 class="text-xl" style="font-family: 'Roboto', sans-serif;">ANTARES PROGRAMMING</h1>
    </div>

    <transition name="humburgerIcon">
      <span v-if="open" @click="MenuOpen()" class="absolute md:hidden right-6 top-3.5 text-4xl">
          <i class="bi bi-x"></i>
      </span>
      <span v-else @click="MenuOpen()" class="absolute md:hidden right-6 top-3.5 text-4xl">
          <i class="bi bi-filter-right"></i>
      </span>
    </transition>

    <ul class="md:flex md:items-center md:px-0 
                px-6 md:pb-0 pb-10 md:static
                absolute mt-0.5 md:mt-0
                md:w-auto top-14
                transition-all delay-75
                duration-400 ease-in-out"
                :class="[open ? '-left-7' : 'left-[-100%]']"
                ref="navbarList" style="font-family: 'Lato', sans-serif;">
      <li v-for="(link, index) in Links" :key="index" @click="scrollToSection(link.link)"
                class="h-10 md:mx-2 md:my-0 pl-10 md:pl-0
                md:h-full md:w-full bg-opacity-60 bg-gray-900 \
                md:bg-opacity-1 md:bg-inherit overflow-hidden md:overflow-visible
                transition-all duration-700 ease-in-out" 
                :class="[open ? ['w-40',`delay-${(1+index)*50}`] : 'w-0']" style="font-family: 'Roboto', sans-serif;">
        <div @click="link.link === '/' ? $emit('scrollPosition',['div2',link.link]) : $emit('scrollPosition',['div1',link.link]); analyticsClicked(index);">
          <router-link :to="link.link">
            <a aria-label="antares Page" href="#" @click="open=!open;" 
                    class="text-xl hover:text-blue-500">{{ $t(link.name) }}
            </a>
          </router-link>
        </div>
      </li>     
    </ul>
  </div>
</div>
</template>

<script>
/* eslint-disable*/
import { ref, watchEffect } from 'vue';
import { mapMutations, mapState } from 'vuex';
import { useClickOutside } from '@/includes/useClickOutside';

export default {
  components: {
  },
  emits: ['scrollPosition', 'openNavBar'],
  setup(props, { emit }) {
    const Links = [
      { name: 'About', link: '#about' },
      { name: 'Experience', link: '#experience' },
      { name: 'Education', link: '#education' },
      { name: 'Hobby', link: '#hobby' },
      { name: 'Contact', link: '#contact' },
      { name: 'BACK', link: '/' },
    ];
    
    const open = ref(false);

    const navbarList = ref(null);
    const navBar = ref(null);
    useClickOutside(navbarList, navBar, () => {
      if (open.value === true) {
        open.value = false;
      }
    });

    function MenuOpen() {
      open.value = !open.value;
    }

    watchEffect(() => {
      emit('openNavBar', open.value);
    });

    return {
      open, MenuOpen, Links, navbarList, navBar,
    };
  },
  computed: {
  },
  methods: {
    scrollToSection(link) {
      if (link === '/') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        const section = document.querySelector(link);
        window.scrollTo({ top: (section.offsetTop - 60), behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped>
#antares-star-logo{
    animation: mover 20s infinite alternate;
}
@keyframes mover{
    0%{transform:rotate(360deg);}
    14.3%{transform:rotate(300deg);}
    28.5%{transform:rotate(60deg);}
    50.0%{transform:rotate(360deg);}
    57.2%{transform:rotate(-270deg);}
    71.5%{transform:rotate(-200deg);}
    85.77%{transform:rotate(-270deg);}
    100%{transform:rotate(0deg);}
}
.humburgerIcon-enter-from,
.humburgerIcon-leave-to{
  opacity: 0;
  /* transform: translateX(30px); */
}
.humburgerIcon-enter-active{
 transition: all 1s linear;
}
.humburgerIcon-leave-active{
   transition: all 1s linear;
   opacity: 0;
}

</style>
