import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBbrgjIDS3sHevnaq53xXZw8XSEBF5oi18',
  authDomain: 'antareslosinj.firebaseapp.com',
  projectId: 'antareslosinj',
  storageBucket: 'antareslosinj.appspot.com',
  messagingSenderId: '409990147471',
  appId: '1:409990147471:web:5b86c709156c312a4f6ffa',
  measurementId: 'G-H8ZPV9183K',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

db.enablePersistence().catch((error) => {
  // console.log(`Firebase persistance error ${error.code}`);
});

const usersCollection = db.collection('users');
const messageCollection = db.collection('messages');
const messageDemoCollection = db.collection('messagesDemo');
const calendarComfortCollection = db.collection('calendarComfort');
const calendarSuperiorCollection = db.collection('calendarSuperior');
const calendarDemoCollection = db.collection('calendarDemo');
const resumeCollection = db.collection('resumeMatijaCorak');
const specialOffersComfortCollection = db.collection('specialOffersComfort');
const specialOffersSuperiorCollection = db.collection('specialOffersSuperior');
const specialOffersDemoCollection = db.collection('specialOffersDemo');
const individualPinsCollection = db.collection('individualPins');

export {
  auth,
  db,
  usersCollection,
  messageCollection,
  messageDemoCollection,
  calendarComfortCollection,
  calendarSuperiorCollection,
  calendarDemoCollection,
  resumeCollection,
  specialOffersComfortCollection,
  specialOffersSuperiorCollection,
  specialOffersDemoCollection,
  individualPinsCollection,
  storage,
};
