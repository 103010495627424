import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
/* eslint-disable */ // @ts-ignore
import VeeValidatePlugin from './includes/validation';
import './assets/tailwind.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ProgressBar from './includes/progress-bar';
import 'nprogress/nprogress.css'
ProgressBar(router);

/* eslint-disable */ // @ts-ignore
import { auth } from './includes/firebase';
import i18n from './includes/i18n';

import VueGtag from 'vue-gtag';
import ProgressiveImage from 'progressive-image/dist/vue';
let app:any;

auth.onAuthStateChanged(() => {
  if(!app) {
    app = createApp(App);
    app.use(ProgressiveImage, {
      removePreview: true
    });
    app.use(VueGtag, {
      config: {id:'UA-227050616-1'}
    });
    app.use(i18n);
    app.use(store);
    app.use(router);
    app.use(VeeValidatePlugin);
    app.mount('#app');
  }
});

