import NProgress from 'nprogress';

export default (router) => {
  NProgress.configure({ showSpinner: false });
  NProgress.inc();
  // NProgress.configure({ parent: '#container' });
  router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
  });
  router.afterEach(NProgress.done);
};
