<template>
<!-- eslint-disable -->
<div class="relative">
  <div class="fixed top-0 left-0 right-0 bg-gray-900 bg-opacity-60
              py-3.5 px-6 shadow md:flex justify-between items-center"
        style="color:#20C5B5;"
        ref="navBar">

    <div class="flex items-center">
      <img class="object-scale-down"
           style= "width:30px; height:30px;"
           id="antares-star-logo" 
           alt="antares apartments mali losinj logo" 
           src="../assets/slikice/antaresWebp.webp" />
      <h1 class="text-xl" style="font-family: 'Roboto', sans-serif;">Antares Apartments</h1>
    </div>

    <transition name="humburgerIcon">
      <span v-if="open" @click="MenuOpen()" class="absolute md:hidden right-6 top-3.5 text-4xl">
          <i class="bi bi-x"></i>
      </span>
      <span v-else @click="MenuOpen()" class="absolute md:hidden right-6 top-3.5 text-4xl">
          <i class="bi bi-filter-right"></i>
      </span>
    </transition>

    <ul class="md:flex md:items-center md:px-0 
                px-6 md:pb-0 pb-10 md:static
                absolute mt-0.5 md:mt-0
                md:w-auto top-14
                transition-all delay-75
                duration-400 ease-in-out"
                :class="[open ? '-left-7' : 'left-[-100%]']"
                ref="navbarList" style="font-family: 'Lato', sans-serif;">
      <li v-for="(link, index) in Links" :key="index"
                class="h-10 md:mx-2 md:my-0 pl-10 md:pl-0
                md:h-full md:w-full bg-opacity-60 bg-gray-900 \
                md:bg-opacity-1 md:bg-inherit 
                transition-all duration-700 ease-in-out"
                :class="[open ? ['w-40',`delay-${(1+index)*50}`] : 'w-0']" >

        <div v-if="link.name == 'navbar.apartments'" >
          <!-- <div class="relative text-xl">
            <button @click.prevent="MenuOpenApartman()" class="cursor-pointer">
              {{ $t('navbar.apartments') }}
            </button>
            <div class="dropdown-menu">
              <div class="dropdown-menu-content">
                <transition-group name="fade">
                  <div v-if="openApp" class="dropdown-menu-item">
                    <router-link to="/OurApartments" @click="[MenuOpenApartman(),$emit('scrollPosition',['div1','/OurApartments']), open=false]">
                      {{ $t('navbar.apartmentsInfo') }}
                    </router-link>
                  </div>
                  <div v-if="openApp && calendarVisible" class="dropdown-menu-item">
                    <router-link to="/OurCalendar" @click="[MenuOpenApartman(),$emit('scrollPosition',['div1','/OurCalendar']), open=false]">
                      {{ $t('navbar.apartmentsAvailability') }}
                    </router-link>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>  -->
          <div v-if="calendarVisible" class="relative text-xl">
              <button @click.prevent="MenuOpenApartman()" class="cursor-pointer">
                {{ $t('navbar.apartments') }}
              </button>
            <div class="dropdown-menu">
              <div class="dropdown-menu-content">
                <transition-group name="fade">
                  <div v-if="openApp" class="dropdown-menu-item">
                    <router-link to="/OurApartments" @click="[MenuOpenApartman(),$emit('scrollPosition',['div1','/OurApartments']), open=false]">
                      {{ $t('navbar.apartmentsInfo') }}
                    </router-link>
                  </div>
                  <div v-if="openApp && calendarVisible" class="dropdown-menu-item">
                    <router-link to="/OurCalendar" @click="[MenuOpenApartman(),$emit('scrollPosition',['div1','/OurCalendar']), open=false]">
                      {{ $t('navbar.apartmentsAvailability') }}
                    </router-link>
                  </div>
                </transition-group>
              </div>
            </div>
          </div> 
          <div v-else>
            <div class="relative text-xl">
              <router-link to="/OurApartments" @click="[$emit('scrollPosition',['div1','/OurApartments']), open=false]">
                      {{ $t('navbar.apartments') }}
              </router-link>
            </div>
          </div>          
        </div>
        <div v-else>
          <div @click="link.link === '/' ? $emit('scrollPosition',['div2',link.link]) : $emit('scrollPosition',['div1',link.link]);">
            <router-link :to="link.link">
              <a aria-label="antares Page" href="#" @click="open=!open;" class="text-xl hover:text-blue-500 ">
                <span>{{ $t(link.name) }}</span>               
              </a>
            </router-link>
          </div>
        </div>

      </li>
      
      <li @click="toggleContactModal(); open=!open;" class="h-10 md:mx-2 md:my-0 pl-10 md:pl-0
                md:h-full md:w-full bg-opacity-60 bg-gray-900 \
                md:bg-opacity-1 md:bg-inherit overflow-hidden md:overflow-visible
                transition-all duration-700 ease-in-out
                text-xl hover:text-blue-500 cursor-pointer" 
                :class="[open ? ['w-40',`delay-${(1+5)*50}`] : 'w-0']" >
        {{ $t('navbar.contactUs') }}
      </li>
      
      <li v-if="userLoggedIn" class="h-10 md:mx-2 md:my-0 pl-10 md:pl-0
                md:h-full md:w-full bg-opacity-60 bg-gray-900 \
                md:bg-opacity-1 md:bg-inherit 
                transition-all duration-700 ease-in-out"
                :class="[open ? ['w-40',`delay-${(1+index)*50}`] : 'w-0']">
        <div class="relative text-xl">
          <button @click.prevent="MenuOpenProfile()" class="cursor-pointer">
            {{ $t('navbar.profile') }}
          </button>
          <div class="dropdown-menu">
            <div class="dropdown-menu-content">
              <transition-group name="fade">
                <div v-if="openProfile" class="dropdown-menu-item" >
                  <router-link to="/MyProfile" @click="[MenuOpenProfile(),$emit('scrollPosition',['div1','/MyProfile']), open=false]">
                    {{ $t('navbar.myProfile') }}
                  </router-link>
                </div>
                <div v-if="openProfile" class="dropdown-menu-item" >
                  <router-link to="/" @click="[MenuOpenProfile(),$emit('scrollPosition',['div1','/logout']), signout(), open=false]">
                    {{ $t('navbar.logout') }}
                  </router-link>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </li>
      <li>
      <LanguageDropdownComponent  :openParent="openLanguage" :languages="Languages" @click="[openProfile=false, openApp=false]"
          class=" md:mx-4 md:my-0 pl-10 md:pl-0 h-10 w-40 md:h-full md:w-full bg-opacity-60
                  bg-gray-900 md:bg-opacity-1 md:bg-inherit transition-all duration-700 ease-in"
          :class="[open ? ['w-40',`delay-400`] : 'w-0']"  @update:openParent="handleOpenParentUpdate"/>
      </li>
    </ul>
    <ContactUsModal class="text-black"/>
  </div>
</div>
</template>

<script>
/* eslint-disable*/
import { ref, watchEffect } from 'vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import ContactUsModal from '@/components/Forms/ContactUsModal.vue';
import { useClickOutside } from '@/includes/useClickOutside';
import LanguageDropdownComponent from './LanguageDropdownComponent.vue';

export default {
  components: {
    LanguageDropdownComponent,
    ContactUsModal,
  },
  data(){
    return {
    }
  },
  emits: ['scrollPosition', 'openNavBar'],
  setup(props, { emit }) {
    const Links = [
      { name: 'navbar.home', link: '/' },
      { name: 'navbar.aboutUs', link: '/AboutUs' },
      { name: 'navbar.apartments', link: '/OurApartments' },
      { name: 'navbar.findUs', link: '/FindUs' },
      { name: 'navbar.awards', link: '/OurAwards' },
      // { name: 'ContactUs ', link: '/ContactUs' },
    ];
    const Languages = [
      /* eslint-disable global-require */
      { name: 'English', image: require('@/assets/slikice/countries/flags22x17/GB.png'), short: 'en' },
      { name: 'Croatian', image: require('@/assets/slikice/countries/flags22x17/HR.png'), short: 'hr' },
      // { name: 'Italian', image: require('@/assets/slikice/countries/flags22x17/IT.png'), short: 'it' },
      // { name: 'German', image: require('@/assets/slikice/countries/flags22x17/DE.png'), short: 'gr' },
      /* eslint-enable global-require */
    ];
    const open = ref(false);
    const openLanguage = ref(false);
    const openApp = ref(false);
    const openProfile = ref(false);

    const navbarList = ref(null);
    const navBar = ref(null);
    useClickOutside(navbarList, navBar, () => {
      open.value = false;
      openApp.value = false;
      openProfile.value = false;
    });

    function MenuOpenApartman() {
      // open.value = !open.value;
      openApp.value = !openApp.value;
      openProfile.value = false;
      openLanguage.value = false;
    }
    function MenuOpenProfile() {
      // open.value = !open.value;
      openProfile.value = !openProfile.value;
      openApp.value = false;
      openLanguage.value = false;
    }
    function MenuOpen() {
      open.value = !open.value;
      openApp.value = false;
      openProfile.value = false;
      openLanguage.value = false;
    }

    function handleOpenParentUpdate(value) {
      openLanguage.value = value;
    }

    watchEffect(() => {
      emit('openNavBar', open.value);
    });

    return {
      handleOpenParentUpdate, open, openApp, openProfile, openLanguage, MenuOpen, MenuOpenApartman, MenuOpenProfile, Languages, Links, navbarList, navBar,
    };
  },
  computed: {
    ...mapState(['userLoggedIn', 'calendarVisible']),
  },
  methods: {
    ...mapMutations(['toggleContactModal']),
     ...mapActions(['calendarVisibility']),
    signout() {
      this.$store.dispatch('signout');

      if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: 'home' });
      }
    },
  },
  created() {
    this.calendarVisibility();
  },
};
</script>

<style scoped>
#antares-star-logo{
    animation: mover 20s infinite alternate;
}
@keyframes mover{
    0%{transform:rotate(360deg);}
    14.3%{transform:rotate(300deg);}
    28.5%{transform:rotate(60deg);}
    50.0%{transform:rotate(360deg);}
    57.2%{transform:rotate(-270deg);}
    71.5%{transform:rotate(-200deg);}
    85.77%{transform:rotate(-270deg);}
    100%{transform:rotate(0deg);}
}
.humburgerIcon-enter-from,
.humburgerIcon-leave-to{
  opacity: 0;
  /* transform: translateX(30px); */
}
.humburgerIcon-enter-active{
 transition: all 1s linear;
}
.humburgerIcon-leave-active{
   transition: all 1s linear;
   opacity: 0;
}



.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  transform: translatey(-20px);
}
.fade-enter-active,
.fade-leave-active{
 transition: all 1s ease-in-out;
 transition-delay: calc( 100ms * var(--index) );
}
.relative {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  left: 120px;
  top: -15px;

}

@media (min-width: 1050px) {
  .dropdown-menu {
    left: -10px;
    /* left: 0px; */
    /* top: calc(-25% - 4px); */
    top: 43px;
  }
  .dropdown-menu-item {
  padding: 0px;
 }
}

.dropdown-menu-content {
  /* padding-bottom: 1.25rem; equivalent to 5px with default font-size */
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  /* background-color: #1f2937;
  opacity: 0.6; */
  background-color: rgba(31, 41, 55, 0.7);
  opacity: 1;
  
}

.dropdown-menu-item {
  padding: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-menu-item:hover, .cursor-pointer:hover {
  color: #3b82f6;
}

</style>
