<template>
<!-- eslint-disable -->
  <div class="relative">
    <button @click.prevent="MenuOpen()" class="block h-6 w-8 focus:outline-none cursor-pointer">
      <img :src="languages[chosenLanguage].image" alt="chosenLanguage" class="p-0.5 h-full w-full object-cover"/>
    </button>
    <div class="absolute left-40 -top-5 md:-left-14 md:top-5">
      <div class="mt-0 pb-5">
        <transition-group name="fade">
            <br :key="5">
            <div  v-if="open" v-for="(language, index) in languages" :key="language" 
                            class="shadow-xl bg-gray-900 bg-opacity-60" :style="{'--index': index}"
                            @click.prevent="changeLocale(index); open = !open">
              <a aria-label="antares country selection" href="" class="block px-3 py-1 text-white-800 hover:text-blue-500 flex mr-10" >
                <img :src="language.image" alt="flag" class="rounded-full hover:border 
                                                            hover:border-blue-500 shadow-sm 
                                                            w-4/4 object-fill z-10"/>
                <span class="px-1">{{language.name}}</span>
              </a>
            </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  watchEffect,
} from 'vue';

export default {
  props: {
    languages: {
      type: Array,
      required: true,
    },
    openParent: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    let open = ref(false);
    const chosenLanguage = ref(0);

    const whyToUseIt = computed(() => {
      if (props.openParent === false) {
        open = false;
      }
      return open;
    });
    watchEffect(() => {
      open.value = props.openParent;
    });

    function MenuOpen() {
      open.value = !open.value;
      emit('update:openParent', true);
    }
    return {
      open,
      MenuOpen,
      chosenLanguage,
    };
  },
  methods: {
    changeLocale(index) {
      this.chosenLanguage = index;
      this.$i18n.locale = this.languages[this.chosenLanguage].short;
    },
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to{
  opacity: 0;
  transform: translatey(-20px);
}
.fade-enter-active,
.fade-leave-active{
 transition: all 1s ease-in-out;
 transition-delay: calc( 100ms * var(--index) );
}
</style>
