<template>
<!-- eslint-disable -->
    <section class="w-screen text-center">
      <div id="background" class="items-center">
        <div class="grid grid-cols-1 px-0 mb-5">
          <h1 class="text-white text-2xl">
            {{ $t('footer.contactUs') }}
          </h1>

          <div style="position:relative; height:90px; ">
            <ul>
              <li><a href="tel:00385912217145"  aria-label="Contact Us"><i class="bi bi-telephone-fill" aria-hidden="true" title="CallUs"></i></a></li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=385912217145&text=Hi I would like to contact you"
                    target="https://api.whatsapp.com/send?phone=385912217145&text=Hi I would like to contact you"
                    aria-label="Click and WhatsApp us" title="WhatsAppUs">
                  <i class="bi bi-whatsapp" aria-hidden="true"></i>
                </a>
              </li>
              <li><a href="mailto:suzicorak@gmail.com"  aria-label="Email Us"><i class="bi bi-envelope" aria-hidden="true" title="EmailUs"></i></a></li>
            </ul>
            
          </div>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;tel: (+385) 91 221 7145 | email: suzicorak@gmail.com</p>
          <br>
          <div class="text-xl">
            <h1 class="text-white text-2xl">
            {{ $t('footer.visitUs') }}
          </h1>
          </div>
          <div style="position:relative; height:90px;">
            <ul>
              <!-- face -->
              <li>
                <a href="https://www.facebook.com/AntaresMaliLosinj" target="https://www.facebook.com/AntaresMaliLosinj" aria-label="Check out our Facebook page" title="Facebook">
                  <i class="bi bi-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <!-- booking -->
              <li>
                <a href="https://www.booking.com/hotel/hr/apartments-antares-mali-losinj.hr.html?aid=304142;label=gen173nr-1DCAEoggI46AdIM1gEaJ0BiAEBmAEQuAEXyAEM2AED6AEBiAIBqAIDuALjjo2SBsACAdICJDI3MWM3ODgwLTFiMDItNDUyZC05N2FmLTM3ZWYyMTRiYzkzMtgCBOACAQ;sid=e3fc041c508be97876b7b72a118c1077;dest_id=-88281;dest_type=city;dist=0;group_adults=1;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;srepoch=1648576421;srpvid=8eef7dd18da600c9;type=total;ucfs=1&#hotelTmpl" 
                    target="https://www.booking.com/hotel/hr/apartments-antares-mali-losinj.hr.html?aid=304142;label=gen173nr-1DCAEoggI46AdIM1gEaJ0BiAEBmAEQuAEXyAEM2AED6AEBiAIBqAIDuALjjo2SBsACAdICJDI3MWM3ODgwLTFiMDItNDUyZC05N2FmLTM3ZWYyMTRiYzkzMtgCBOACAQ;sid=e3fc041c508be97876b7b72a118c1077;dest_id=-88281;dest_type=city;dist=0;group_adults=1;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=1;req_children=0;room1=A;sb_price_type=total;sr_order=popularity;srepoch=1648576421;srpvid=8eef7dd18da600c9;type=total;ucfs=1&#hotelTmpl"
                    aria-label="Check out our Booking page" title="Booking">
                  <p class="bi" style="position: relative;">
                    <img src="@/assets/slikice/booking.svg" class="bi-booking" style="width:30px; height:30px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);" alt="antares apartments mali losinj booking">
                  </p>
                </a>
              </li>
              <!-- google -->
              <li>
                <a href="https://www.google.com/travel/hotels/google%20antares%20mali%20losinj/entity/CgoIlu-Lyur24fl0EAE/overview?q=google%20antares%20mali%20losinj&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4401769%2C4429192%2C4570332%2C4597339%2C4640247%2C4647135%2C4649665%2C4680677%2C4721475%2C4722435%2C4722900%2C4723331%2C4726607%2C4733969%2C4734935%2C4736008%2C4738606%2C4741186%2C4743499&hl=hr-MU&gl=mu&ssta=1&rp=EJbvi8rq9uH5dBCW74vK6vbh-XQ4AkAASAHAAQI&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0NzYyZjlmODAwMGJkYjA3OjB4NzRmMzg3YjZhOTQyZjc5NhoAEhoSFAoHCOYPEAQYHBIHCOYPEAQYHRgBMgIQACoECgAaAA" 
                  target="https://www.google.com/travel/hotels/google%20antares%20mali%20losinj/entity/CgoIlu-Lyur24fl0EAE/overview?q=google%20antares%20mali%20losinj&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4401769%2C4429192%2C4570332%2C4597339%2C4640247%2C4647135%2C4649665%2C4680677%2C4721475%2C4722435%2C4722900%2C4723331%2C4726607%2C4733969%2C4734935%2C4736008%2C4738606%2C4741186%2C4743499&hl=hr-MU&gl=mu&ssta=1&rp=EJbvi8rq9uH5dBCW74vK6vbh-XQ4AkAASAHAAQI&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0NzYyZjlmODAwMGJkYjA3OjB4NzRmMzg3YjZhOTQyZjc5NhoAEhoSFAoHCOYPEAQYHBIHCOYPEAQYHRgBMgIQACoECgAaAA"
                  aria-label="Check out our Google page" title="Google">
                  <i class="bi bi-google" aria-hidden="true"></i>
                </a>
              </li>
              <!-- trip -->
              <li>
                <a href="https://www.tripadvisor.com/VacationRentalReview-g297514-d12166750-Antares_Comfort_Plus-Mali_Losinj_Losinj_Island_Kvarner_Bay_Islands_Primorje_Gorski_Ko.html" 
                    target="https://www.tripadvisor.com/VacationRentalReview-g297514-d12166750-Antares_Comfort_Plus-Mali_Losinj_Losinj_Island_Kvarner_Bay_Islands_Primorje_Gorski_Ko.html"
                    aria-label="Check out our TripAdvisor page" title="TripAdvisor">
                  <p class="bi" style="position: relative;">
                    <img src="@/assets/slikice/tripadvisor.svg" class="bi-tripadvisor" style="width:30px; height:30px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); " alt="antares apartments mali losinj tripadvisor">
                  </p>
                </a>
              </li>
              <!-- instagram -->
              <li>
                <a href="https://www.instagram.com/antares_apartment_mali_losinj/" aria-label="Check out our Instagram page" title="Instagram">
                  <i class="bi bi-instagram" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
          <br>
          <div>           
            <h1 class="text-white text-l">
              {{ $t('footer.knowMore') }}
          </h1>
          </div>
          <div style="position:relative; height:90px;">
            <ul>
              <li>
                <a href="./ANTARES-info.pdf" download aria-label="More Information about us" title="MoreInfo">
                  <i class="bi bi-download" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
          
      </div>
      <div class="text-white bg-black pt-1">
          <p >
            <span style="font-family: 'Great Vibes', sans-serif;">
              Made with 
              <i @click="toggleAuthModal" :class="[this.$store.state.userLoggedIn ? ['bi bi-heart-fill'] : 'bi bi-heart']" class="text-red-600"></i>
              by 
            </span>
            <router-link to="/MyResume" @click.prevent="scrollToTop">Matija Corak</router-link>
          </p>
      </div>
    </div>
    <AuthComp/>
  </section>
</template>
<script>
import AuthComp from '@/components/Forms/AuthComp.vue';
import { mapMutations } from 'vuex';

export default {
  components: {
    AuthComp,
  },
  methods: {
    ...mapMutations(['toggleAuthModal']),
    scrollToTop() {
      this.$emit('scroll-to-top');
    },
  },
};
</script>
<style scoped>

#background{
    font-family: 'Montserrat', sans-serif;
    padding-top:20px;
    background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(@/assets/slikice/footer.webp);
    background-position:center;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;
    border-top:3px solid #20C5B5;

}

ul {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  margin:0;
  padding:0;
  display:flex;
}

ul li {
  list-style: none;
}

ul li a {
  position: relative;
  width:60px;
  height:60px;
  display:block;
  text-align:center;
  margin:0 10px;
  border-radius: 50%;
  padding: 6px;
  box-sizing: border-box;
  text-decoration:none;
  box-shadow: 0 10px 15px rgba(0,0,0,0.3);
  background: linear-gradient(0deg, #ddd, #fff);
  transition: .5s;
}

@media (max-width:500px) {
    ul li a{
      margin:0 5px;
    }
}

ul li a:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  text-decoration:none;
}

ul li a .bi {
  width: 100%;
  height:100%;
  display:block;
  background: linear-gradient(0deg, #fff, #ddd);
  border-radius: 50%;
  line-height: calc(60px - 12px);
  font-size:24px;
  color: #262626;
  transition: .5s;
}

ul li a:hover .bi-facebook {
  color: #3b5998;
}

ul li a:hover .bi-booking {
  filter: invert(80%) sepia(70%) saturate(1200%) hue-rotate(170deg) brightness(50%) contrast(50%);
}

ul li a:hover .bi-google {
  color: #dd4b39;
}

ul li a:hover .bi-tripadvisor {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}

ul li a:hover .bi-instagram {
  color: #e4405f;
}
ul li a:hover .bi-telephone-fill{
  color:#007bb6;
}
ul li a:hover .bi-whatsapp {
  color: lawngreen;
}
ul li a:hover .bi-envelope {
  color: #00aced;
}
ul li a:hover .bi-download {
  color:darkblue;
}
</style>
