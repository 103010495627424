/* eslint-disable */
/**
 * @param {*} el_target_ref1 //the root element for which clicking outside will triger callback fn
 * @param {*} el_target_ref2 //the root element for which clicking outside will triger callback fn
 * @param {*} callback_fn // the function to call when user clicks outside of
 * @returns
 */

import { onBeforeUnmount, onMounted } from 'vue';

export function useClickOutside(el_target_ref1, el_target_ref2, callback_fn) {
  if (!el_target_ref1 || !el_target_ref2) return;
  let listener = (e) => {
    if (e.target == el_target_ref1.value || e.composedPath().includes(el_target_ref1.value) || e.target == el_target_ref2.value || e.composedPath().includes(el_target_ref2.value)) {
      //We clickedinside modal/active region
      return
    }
    //at this point we clicked outside the modal
    if (typeof callback_fn == 'function') {
      callback_fn();
    }
  };

  onMounted(() => {
    window.addEventListener ('click', listener);
  })
  onBeforeUnmount(() => {
    window.removeEventListener('click', listener);
  })

  return {
    listener,
  };
}
