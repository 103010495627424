<template>
<!-- eslint-disable -->
  <!-- Auth Modal -->
  <div class="fixed z-50 inset-0 overflow-y-auto" id="modal"
    :class="{ hidden: !authModalShow }" style="font-family: 'Montserrat', serif;">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center
      sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-800 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
        shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-4">
            <p class="text-2xl font-bold">Your Account</p>
            <!-- Modal Close Button -->
            <div class="modal-close cursor-pointer z-50 z-90 w-3 h-3 bg-white" @click.prevent="toggleAuthModal">
              <i class="bi bi-x-square" style="width:100%;"></i>
            </div>
          </div>

          <!-- Tabs -->
          <ul class="flex flex-wrap mb-4">
            <li class="flex-auto text-center">
              <a aria-label="antares Auth" class="block rounded py-3 px-4 transition"
                href="#" @click.prevent="tab = 'login'"
                :class="{
                  'hover:text-white text-white bg-blue-600': tab === 'login',
                  'hover:text-blue-600': tab === 'register'
                }">
                Login
              </a>
            </li>
            <li class="flex-auto text-center">
              <!-- <a aria-label="antares register" class="block rounded py-3 px-4 transition"
                href="#" @click.prevent="tab = 'register'"
                :class="{
                  'hover:text-white text-white bg-blue-600': tab === 'register',
                  'hover:text-blue-600': tab === 'login'
                }">Register</a> -->
            </li>
          </ul>

          <app-login-form v-if="tab === 'login'" />
          <app-register-form v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import AppLoginForm from '@/components/Forms/LoginForm.vue';
import AppRegisterForm from '@/components/Forms/RegisterForm.vue';

export default {
  name: 'AuthComp',
  components: {
    AppLoginForm, AppRegisterForm,
  },
  data() {
    return {
      tab: 'login',
    };
  },
  computed: {
    // ...mapState({
    //   modal: 'authModalShow',
    // }),
    ...mapState(['authModalShow']),
  },
  methods: {
    ...mapMutations(['toggleAuthModal']),
  },
};
</script>
