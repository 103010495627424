/* eslint-disable */
import { createStore } from 'vuex';
import {
        db,
        auth,
        usersCollection,
        messageCollection,
        calendarComfortCollection,
        calendarSuperiorCollection,
        calendarDemoCollection,
        specialOffersComfortCollection,
        specialOffersSuperiorCollection,
        specialOffersDemoCollection,
        individualPinsCollection
      } from '@/includes/firebase';

export default createStore({
  state: {
    calendarVisible: false,
    isLoading: false,
    authModalShow: false,
    userLoggedIn: false,
    contactModalShow: false,
    reviewApp:[{id:0,name:'Tine', date:'10/09/2021', description:'',country:'SLO',logo:require('@/assets/slikice/countries/flags30x23/RS.png')},
               {id:1,name:'Hans', date:'13/09/2022', description:"",country:'SWIS',logo:require('@/assets/slikice/countries/flags30x23/CH.png')},
               {id:2,name:'Jonny', date:'01/011/2020', description:'',country:'SWE',logo:require('@/assets/slikice/countries/flags30x23/SE.png')},
               {id:3,name:'Stefan', date:'17/07/2021', description:'',country:'GER',logo:require('@/assets/slikice/countries/flags30x23/DE.png')},
               {id:4,name:'Maciej', date:'29/07/2021', description:'',country:'POL',logo:require('@/assets/slikice/countries/flags30x23/PL.png')},
               {id:5,name:'Beata', date:'07/09/2020', description:"",country:'GER',logo:require('@/assets/slikice/countries/flags30x23/DE.png')},
              ],
  },
  mutations: {
    setIsLoading(state, status) {
      state.isLoading = status
    },
    toggleContactModal: (state) => {
      state.contactModalShow = !state.contactModalShow;
    },
    toggleAuthModal: (state) => {
      if(!state.userLoggedIn){
        state.authModalShow = !state.authModalShow;
      }
    },
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },
    SET_CALENDAR_VISIBILITY(state, isVisible) {
      state.calendarVisible = isVisible;
    },
  },
  getters: {
    // authModalShow: (state) => state.authModalShow,
  },
  actions: {
    async register({ commit }, payload) {
      const userCred = await auth.createUserWithEmailAndPassword(
        payload.email, payload.password,
      );
      // @ts-ignore: Object is possibly 'null'
      await usersCollection.doc(userCred.user.uid).set({
        name: payload.name,
        email: payload.email,
        age: payload.age,
        country: payload.country,
      });
      // @ts-ignore: Object is possibly 'null'
      await userCred.user.updateProfile({
        displayName: payload.name,
      });
      commit('toggleAuth');
    },
    async login({ commit, dispatch }, payload) {
      await auth.signInWithEmailAndPassword(payload.email, payload.password);
      await dispatch('deleteDBIfDemo');
      commit('toggleAuth');
    },
    init_login({ commit }) {
      const user = auth.currentUser;
      if (user) {
        commit('toggleAuth');
      }
    },
    async deleteDBIfDemo({ commit, dispatch }) {
      const user = auth.currentUser;
      if (user && user.uid=="xLByoFtaWvchNEIGWYrJpFHuOJ82") {
        // const collectionRef = db.collection('calendarDemo');
        const batch = db.batch();

        await calendarDemoCollection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
          // return batch.commit();
        }).then(() => {
          // console.log('All documents deleted successfully!');
        }).catch((error) => {
          console.error('Error deleting documents: ', error);
        });

        await specialOffersDemoCollection.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
          return batch.commit();
        }).then(() => {
          // console.log('All documents deleted successfully!');
        }).catch((error) => {
          console.error('Error deleting documents: ', error);
        });

        // await dispatch('insertDBdemo');

      }
    },
    async insertDBdemo({commit}){
      const currentDate = new Date();
      const docData = [];

      for (let i = 0; i < 3; i++) {
        const date = new Date(currentDate.getTime() + (i * 24 * 60 * 60 * 1000));
        const doc = {
          day: date.getDate() + '',
          month: (date.getMonth() + 1).toString().padStart(2, '0') + '',
          year: date.getFullYear() + '',
          type:'notAvailable'
        };
        docData.push(<never>doc);
      }

      Promise.all(docData.map(doc => calendarDemoCollection.doc().set(doc)))
        .then(() => {
          console.log('Documents added successfully!');
        })
        .catch((error) => {
          console.error('Error adding documents: ', error);
        });
    },
    async signout({ commit }) {
      await auth.signOut();
      commit('toggleAuth');
      // if (payload.route.meta.requiresAuth) {
      //   payload.router.push({ name: 'home' });
      // }
    },
    async sendMessage({ commit }, payload) {
      await messageCollection.doc().set({
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        country: payload.country,
        message: payload.message,
      });
    },
    //calendar confort
    async insertDatesComfort({ commit }, payload) {
      let newInsert = await calendarComfortCollection.doc()
      newInsert.set({
        year: payload.year,
        month: payload.month,
        day: payload.day,
        type: payload.type,
      });
      return newInsert.id
    },
    async deleteDatesComfort({ commit }, payload) {
      const docId = payload.id;
      await calendarComfortCollection.doc(docId).delete();
    },
    async updateDatesComfort({ commit }, payload) {
      const docId = payload.id;
      await calendarComfortCollection.doc(docId).update({
        year: payload.year,
        month: payload.month,
        day: payload.day,
        type: payload.type,
      });
    },
    //calendar superior
    async insertDatesSuperior({ commit }, payload) {
      let newInsert = await calendarSuperiorCollection.doc()
      newInsert.set({
        year: payload.year,
        month: payload.month,
        day: payload.day,
        type: payload.type,
      });
      return newInsert.id
    },
    async deleteDatesSuperior({ commit }, payload) {
      const docId = payload.id;
      await calendarSuperiorCollection.doc(docId).delete();
    },
    async updateDatesSuperior({ commit }, payload) {
      const docId = payload.id;
      await calendarSuperiorCollection.doc(docId).update({
        year: payload.year,
        month: payload.month,
        day: payload.day,
        type: payload.type,
      });
    },
    //calendar demo
    async insertDatesDemo({ commit }, payload) {
      let newInsert = await calendarDemoCollection.doc()
      newInsert.set({
        year: payload.year,
        month: payload.month,
        day: payload.day,
        type: payload.type,
      });
      return newInsert.id
    },
    async deleteDatesDemo({ commit }, payload) {
      const docId = payload.id;
      await calendarDemoCollection.doc(docId).delete();
    },
    async updateDatesDemo({ commit }, payload) {
      const docId = payload.id;
      await calendarDemoCollection.doc(docId).update({
        year: payload.year,
        month: payload.month,
        day: payload.day,
        type: payload.type,
      });
    },
    //SPECIAL OFFERS
    async insertSpecialOfferComfort({ commit }, payload) {
      let newInsert = await specialOffersComfortCollection.doc()
      newInsert.set({
        selectedDateFirst: payload.selectedDateFirst,
        selectedDateLast: payload.selectedDateLast,
        price:payload.price
      });
      return newInsert.id
    },
    async deleteSpecialOfferComfort({ commit }, payload) {
      console.log('deleteSpecialOffer')
      const docId = payload.id;
      await specialOffersComfortCollection.doc(docId).delete();
    },
    async updateSpecialOfferComfort({ commit }, payload) {
      const docId = payload.id;
      await specialOffersComfortCollection.doc(docId).update({
        selectedDateFirst: payload.selectedDateFirst,
        selectedDateLast: payload.selectedDateLast,
        price:payload.price
      });
    },
    
    //SPECIAL OFFERS SUPERIOR
    async insertSpecialOfferSuperior({ commit }, payload) {
      let newInsert = await specialOffersSuperiorCollection.doc()
      newInsert.set({
        selectedDateFirst: payload.selectedDateFirst,
        selectedDateLast: payload.selectedDateLast,
        price:payload.price
      });
      return newInsert.id
    },
    async deleteSpecialOfferSuperior({ commit }, payload) {
      console.log('deleteSpecialOffer')
      const docId = payload.id;
      await specialOffersSuperiorCollection.doc(docId).delete();
    },
    async updateSpecialOfferSuperior({ commit }, payload) {
      const docId = payload.id;
      await specialOffersSuperiorCollection.doc(docId).update({
        selectedDateFirst: payload.selectedDateFirst,
        selectedDateLast: payload.selectedDateLast,
        price:payload.price
      });
    },

    //SPECIAL OFFERS Demo
    async insertSpecialOfferDemo({ commit }, payload) {
      let newInsert = await specialOffersDemoCollection.doc()
      newInsert.set({
        selectedDateFirst: payload.selectedDateFirst,
        selectedDateLast: payload.selectedDateLast,
        price:payload.price
      });
      return newInsert.id
    },
    async deleteSpecialOfferDemo({ commit }, payload) {
      console.log('deleteSpecialOffer')
      const docId = payload.id;
      await specialOffersDemoCollection.doc(docId).delete();
    },
    async updateSpecialOfferDemo({ commit }, payload) {
      const docId = payload.id;
      await specialOffersDemoCollection.doc(docId).update({
        selectedDateFirst: payload.selectedDateFirst,
        selectedDateLast: payload.selectedDateLast,
        price:payload.price
      });
    },
    //individual pins
    async updateIndividualPins({ commit }, payload) {
      const docId = payload.id;
      await individualPinsCollection.doc(docId).update({
        calendarAvailable:payload['calendarAvailable']
      });
    },
    async calendarVisibility({ commit }) {
      const calendarAvailable = await individualPinsCollection.doc("RnSuecQCuz823Hl6LVlu").get();
      const isCalendarAvailable = calendarAvailable.get('calendarAvailable');
      // commit('SET_CALENDAR_VISIBILITY', isCalendarAvailable);
    
      const currentUser = auth.currentUser;
      const userId = currentUser ? currentUser.uid : null;
  
      if (isCalendarAvailable || userId == 'xLByoFtaWvchNEIGWYrJpFHuOJ82') {
        commit('SET_CALENDAR_VISIBILITY', true);
      }
    },
    
    // async messageList(){
    //   await usersCollection.doc().get({
    //   });
    // }
  },
});
